import {Pipe, PipeTransform} from '@angular/core';
import {DateTime} from 'luxon';
import {toDateTime} from '../../utils/date.utils';

export const getPassedDays = (date: string | Date) => Math.trunc(DateTime.now().diff(toDateTime(date), 'days').days);

@Pipe({
    name: 'passedDays',
})
export class PassedDaysPipe implements PipeTransform {
    transform(date: string | Date, ...args: unknown[]) {
        return PassedDaysPipe.transform(date, args);
    }

    static transform(date: string | Date, ...args: unknown[]): string {
        const days = getPassedDays(date);
        return `${days} day${days > 1 ? 's' : ''} ago`;
    }
}
